import { ref } from "vue";
import { subjects } from "../../static/index";
import { useField, useForm } from "vee-validate";
import { useAlertStore } from '../../stores';
import { contactUsQuery } from "../../Actions/UserActions";
import configuration from "@/config.json";

export default {
  setup() {
    const { Address, countryLocale, addressLocation, contactNumber } = configuration;
    const alertStore = useAlertStore()
    const { handleSubmit, handleReset, validate } = useForm({
      validationSchema: {
        name(value) {
          if (value?.length >= 4) return true;

          return "Please enter your fullname.";
        },
        phone(value) {
          if (value?.length > 9 && /[0-9-]+/.test(value)) return true;

          return "Phone number needs to be at least 10 digits.";
        },
        remarks(value) {
          if (value?.length >= 10) return true;

          return "Remarks needs to be at least 10 characters.";
        },
        email(value) {
          if (value?.length >= 6) return true;

          return "Please enter valid email.";
        },
        subject(value) {
          if (selectedItems?._value) return true;

          return "Select a subject.";
        },
      },
    });
    const name = useField("name");
    const phone = useField("phone");
    const email = useField("email");
    const subject = useField("select");
    const remarks = useField("remarks");
    const selectedItems = ref([]);

    const submit = handleSubmit((values, actions) => {
        contactUsQuery({
            "reseller_registration": {
                "name": values.name,
                "phone": values.phone,
                "email": values.email,
                "message": values.remarks
            }
        }).then(res => {
          if(res.status === 200) {
            alertStore.setAlertMessage('success', 'ThankYou!! Blubirch team will contact you soon.', true)
            actions.resetForm();
          } else {
            alertStore.setAlertMessage('error', res.data.erorrs, true)
          }
        })
    });

    const contactLink = `tel:${configuration.contactNumber}`;
    const mailLink = `mailto:${configuration.email}`;

    return {
      subjects,
      name,
      contactLink,
      contactNumber,
      orgemail: configuration.email,
      mailLink,
      phone,
      email,
      Address,
      submit,
      addressLocation,
      countryLocale,
      subject,
      remarks,
      selectedItems
    };
  },
};