import insight from "@/../assets/images/insight.png";
import medal from "@/../assets/images/medal.png";
import award from "@/../assets/images/award.png";
import iso from "@/../assets/images/iso_2015.png";
import popControl from "@/../assets/images/pollution_control.png";
import teamImg from "@/../assets/images/bb_group.png";
import flowImg from "@/../assets/images/aboutus_flow.png"
import abtBanner from "@/../assets/images/abt-banner.jpg"
import abtBanner2 from "@/../assets/images/abt-banner2.jpg"
import jeby from "@/../assets/images/jeby.png";
import sapan from "@/../assets/images/sapan.png"
import amit from "@/../assets/images/amit.png"
import certLogo4 from "@/../assets/images/certi-logo4.png"
import certLogo5 from "@/../assets/images/certi-logo5.png"
import configuration from "../../config.json";

export default {
  setup() {
    return {
      insight,
      medal,
      jeby,
      sapan,
      amit,
      certLogo4,
      certLogo5,
      abtBanner2,
      teamImg,
      iso,
      award,
      abtBanner,
      popControl,
      flowImg,
      configuration
    }
  }
}