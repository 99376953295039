<template>
  <div v-if="configuration.countryLocale.locale === 'uae'">
    <UaeFaq />
  </div>
  <div v-else>
    <GlobalFaq />
  </div>
</template>

<script>
import GlobalFaq from "./global.vue";
import UaeFaq from "./uae.vue";
import configuration from "../../config.json";

export default {
  setup() {
    return {
      configuration
    };
  },
  components: {
    GlobalFaq,
    UaeFaq
  }
};
</script>
